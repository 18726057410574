/**
 * Generate accordion component
 *
 * @param id
 * @param head
 * @param body
 * @returns {string}
 */
function accordionComponent({id, head, body}) {
    return `
        <li class="faq__list__item accordion text-neutral-500 border border-primary-100 bg-white rounded-xl overflow-hidden" data-accordion-id="${id}">
            <div class="faq__item__question accordion__head text-lg font-medium p-4 flex justify-between items-center gap-4 cursor-pointer ">
                <span class="faq__item__question__title">${head}</span>
                <button class="faq__button accordion__button accordion--open w-6 h-6"></button>
            </div>
            <div class="faq__item__answer accordion__body p-4 border-primary-100 border-t hidden">
                ${body}
            </div>
        </li>
    `
}


/**
 * Accordion on click event
 * @param event
 */
function accordionOnClick(event) {

    if (!event.target.matches('.accordion')) {
        let faqListItem = event.target.closest('.accordion')
        let faqButton = faqListItem.querySelector('.accordion__button')
        let faqListItemAnswer = faqListItem.querySelector('.accordion__body')


        // change faq button icon
        if (faqButton.classList.contains('accordion--close')) {
            faqButton.classList.add('accordion--open')
            faqButton.classList.remove('accordion--close')
        } else {
            faqButton.classList.add('accordion--close')
            faqButton.classList.remove('accordion--open')
        }

        // display FAQ answer
        faqListItemAnswer.classList.toggle('hidden')
    }

}


export {accordionComponent, accordionOnClick}
