function serviceItem({id, name, icon, shortDescription}) {

    return `
        <li class="service__menu__item service__menu__item--shadow  rounded-3xl bg-white py-12 px-4 flex flex-col items-center relative gap-3 w-full lg:w-1/4" data-id="${id}">
            <div
                class="service__menu__item__icon bg-primary-50 w-[8rem] h-[8rem] rounded-full flex items-center justify-center">
                <div
                    class="service__menu__item__icon bg-primary-100 w-[4.875rem] h-[4.875rem] rounded-full flex items-center justify-center z-10">
                    <img src="${icon}" alt="Yuk vaksin anabul mu!"
                         class="w-[2.625rem]">
                </div>
            </div>
            <span class="service__menu__item__title font-bold text-primary-500 text-xl text-center">${name}</span>
            <p class="service__menu__item__description text-neutral-600 text-base text-center">${shortDescription}</p>
        </li>
    `
}


export {serviceItem}
