function remainingTimeForHuman(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());

    let seconds = Math.floor((total / 1000) % 60);
    let minutes = Math.floor((total / 1000 / 60) % 60);
    let hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    let days = Math.floor(total / (1000 * 60 * 60 * 24));

    hours = hours < 0 ? 0 : hours
    minutes = minutes < 0 ? 0 : minutes
    seconds = seconds < 0 ? 0 : seconds
    days = days < 0 ? 0 : days

    return {
        days,
        hours,
        minutes,
        seconds,
    }
}

export {remainingTimeForHuman}
