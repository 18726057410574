function testimonyItem({id, customerName, testimony, imageSourceUrl, rate}) {


    let testimonyStars = ''


    // create rated stars
    for (let counter = 0; counter < rate; counter++) {
        testimonyStars += '<img src="/images/assets/star.png" alt="Rating dari customer" class="h-7 w-7">'
    }


    // create unrated stars
    for (let counter = rate; counter < 5; counter++) {
        testimonyStars += '<img src="/images/assets/empty-star.png" alt="Bintang tanpa rate" class="h-7 w-7">'
    }


    return `
        <div class="testimony__list__item swiper-slide flex flex-col gap-4" data-id="${id}">
            <div class="testimony__profile flex items-center justify-center gap-3 mb-5">
                <img src="${imageSourceUrl}" alt="Foto profil ${customerName}"
                     class="testimony__profile__photo h-16 w-16">

                <div class="testimony__profile__information w-full">
                    <span class="block mb-2 font-bold text-2xl text-neutral-600">${customerName}</span>
                    <div class="testimony__profie__rating flex items-center justify-start gap-[0.625rem]">${testimonyStars}</div>
                </div>
            </div>

            <p class="testimony__body text-neutral-600 text-lg">${testimony}</p>
        </div>
    `

}

export {testimonyItem}
