function emptyState(title, text) {
    return `
        <div class="empty-state-container text-center w-full">
            <img src="/images/assets/cat-play.png" alt="Kucing sedang bermain" class="mx-auto w-80 h-80">
            <span class="block mt-4 text-primary-500 font-bold lg:text-2xl text-lg">${title}</span>
            <span class="block mt-1 font-medium lg:text-xl text-base text-neutral-500">${text}</span>
        </div>
    `
}

export {emptyState}
