import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import {client} from '../../partials/contentful'
import {serviceItem} from "../../components/service-item";
import {accordionComponent, accordionOnClick} from "../../components/accordion-item";
import {promoSlide} from "../../components/promo-slide";
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";
import {testimonyItem} from "../../components/testimony-item";
import {emptyState} from "../../components/empty-state";


const serviceContainer = document.querySelector('.service__menu')
const faqContainer = document.querySelector('.faq__list')
faqContainer.addEventListener('click', accordionOnClick)

const promoContainer = document.querySelector('.promo-swiper-container')
const promoSliderContainer = document.querySelector('.promo__banner__slider')
const testimonySliderContainer = document.querySelector('.testimony__list')
const testimonyContainer = document.querySelector('.testimony-swiper-container')


function getFAQItemEntries() {

    // faq configuration
    let config = {
        'content_type': 'faq',
        'limit': 5
    }


    return client.getEntries(config).then(entries => {
        return entries
    })
}


function getServices() {
    return client.getEntries({
        'content_type': 'layanan', 'include': 10
    }).then(entries => {
        return entries
    })
}


function createAccordionComponent(entries) {
    let faqItem = ''

    entries.items.forEach(function (item) {

        let body = documentToHtmlString(item.fields['jawaban'])

        faqItem += accordionComponent({
            id: item.sys.id, head: item.fields['pertanyaan'], body
        })

    })

    faqContainer.innerHTML = faqItem
}


function createServiceComponent(entries) {

    let serviceItems = ''

    for (let item of entries.items) {
        serviceItems += serviceItem({
            id: item.sys.id,
            icon: `https://${item.fields['icon'].fields.file.url}`,
            name: item.fields['nama'],
            shortDescription: item.fields['narasiSingkat'],
        })
    }

    serviceContainer.innerHTML = serviceItems

}


function getPromos() {

    let dateNow = new Date()

    let year = dateNow.getFullYear()

    let month = dateNow.getMonth()
    month += 1
    month = month.toString().padStart(2, '0')

    let date = dateNow.getDate().toString().padStart(2, '0')

    const formattedDate = `${year}-${month}-${date}T00:00:00Z`

    return client.getEntries({
        'content_type': 'cabangPenyelenggaraPromo',
        'include': 10,
        'fields.tanggalSelesai[gt]': formattedDate,
    }).then(entries => {

        let promos = []

        for (let item of entries.items) {
            promos[item.fields['promo'].sys.id] = item.fields['promo']
        }

        return promos
    })
}


function getPromoBranches(promoId) {

    // faq configuration
    let config = {
        'content_type': 'cabangPenyelenggaraPromo',
        'include': 10,
        'fields.promo.sys.id': promoId
    }


    return client.getEntries(config).then(entries => {
        return entries
    })
}


function getTestimonies() {

    // faq configuration
    let config = {
        'content_type': 'testimoni',
        'include': 10,
    }


    return client.getEntries(config).then(entries => {
        return entries
    })
}


/**
 * Render the promo and check the branches
 * @param items
 * @returns {Promise<void>}
 */
async function createPromoComponents(items) {
    let promoComponentElements = ''

    for (let itemKey in items) {

        await getPromoBranches(items[itemKey].sys.id)
            .then(branchEntries => {

                // only render the promo if only there's a branch
                if (branchEntries.items.length) {

                    promoComponentElements += promoSlide({
                        id: items[itemKey].sys.id,
                        imageSourceUrl: `https://${items[itemKey].fields['banner'].fields.file.url}`,
                        name: items[itemKey].fields['nama'],
                        branches: branchEntries.items
                    })

                    return promoComponentElements
                }

            })

    }

    promoSliderContainer.innerHTML = promoComponentElements
}


async function createTestimonies(entries) {
    let testimonyElements = ''

    for (let item of entries.items) {

        testimonyElements += testimonyItem({
            id: item.sys.id,
            customerName: item.fields['namaPelanggan'],
            testimony: item.fields['testimoni'],
            imageSourceUrl: `https://${item.fields['fotoPelanggan'].fields.file.url}`,
            rate: item.fields['rating'],
        })
    }

    testimonySliderContainer.innerHTML = testimonyElements
}


function initContent() {
    getServices()
        .then(entries => {
            createServiceComponent(entries)
        })


    getFAQItemEntries()
        .then(entries => {
            createAccordionComponent(entries)
        })

    getPromos()
        .then(items => {

            if (Object.keys(items).length) {
                createPromoComponents(items)
                    .then(() => {

                        // init promo swiper
                        new Swiper('.promo__banner.swiper', {
                            centeredSlides: true,
                            autoplay: {
                                delay: 4000,
                                disableOnInteraction: false
                            },
                            navigation: {
                                nextEl: ".promo-swiper-container .swiper-button-next",
                                prevEl: ".promo-swiper-container .swiper-button-prev",
                            },
                            pagination: {
                                el: ".promo-swiper-container .swiper-pagination",
                                clickable: true
                            },
                            loop: true
                        });

                    })
            } else {
                promoContainer.innerHTML = emptyState('Tidak Ada Promo', 'Saat ini belum ada promo yang dapat kami tawarkan.')
            }
        })


    getTestimonies()
        .then(entries => {

            if (Object.keys(entries.items).length) {
                createTestimonies(entries)
                    .then(() => {

                        // init testimony swiper
                        new Swiper('.testimony-swiper.swiper', {
                            centeredSlides: true,
                            autoplay: {
                                delay: 4000,
                                disableOnInteraction: false
                            },
                            pagination: {
                                el: ".testimony-swiper-container .swiper-pagination",
                                clickable: true
                            },
                            // loop: true
                        });

                    })
            } else {
                testimonyContainer.innerHTML = emptyState('Belum Ada Testimoni', 'Saat ini belum ada testimoni dari pelanggan.')
            }
        })


    // init hero swiper
    new Swiper('.hero-swiper.swiper', {
        centeredSlides: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
        loop: true
    });
}

initContent()


