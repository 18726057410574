import {remainingTimeForHuman} from "../partials/time";

function promoSlide({id, name, imageSourceUrl, branches}) {

    let branchText = ''

    if (branches.length) {
        branchText = branches[0].fields['cabang'].fields['nama']
    }

    if (branches.length > 1) {
        branchText += ', dan lainnya'
    }


    let remainingTime = remainingTimeForHuman(new Date(branches[0].fields['tanggalSelesai']))

    return `
        <a href="/events/${id}" class="swiper-slide promo__banner_item rounded-0 lg:rounded-3xl" data-id="${id}">
            <img src="${imageSourceUrl}" alt="${name}" class="h-auto">
            <div class="promo__banner__information bg-white p-3 flex justify-between items-center hidden lg:flex">
                <div class="promo__banner__information__location">
                    <span class="text-sm text-neutral-400">Tersedia di:</span>
                    <span class="font-bold text-primary-500">${branchText}</span>
                </div>
                <div class="promo__banner__information__duration flex items-center">
                    <span class="text-sm text-neutral-400 mr-3">Berlaku hingga:</span>
                    <div
                        class="promo__duration__days flex items-center justify-center border border-neutral-200 p-2 rounded-xl">
                        <div class="flex justify-center items-center flex-col w-[44px] h-[44px]">
                            <span class="promo__banner__hour font-bold text-primary-500">${remainingTime['days']}</span>
                            <span class="text-sm font-light text-primary-400">Hari</span>
                        </div>
                        <span class="font-bold text-primary-500 mx-2 self-baseline">:</span>
                        <div class="flex justify-center items-center flex-col w-[44px] h-[44px]">
                            <span class="promo__banner__minute font-bold text-primary-500">${remainingTime['hours']}</span>
                            <span class="text-sm font-light text-primary-400">Jam</span>
                        </div>
                        <span class="font-bold text-primary-500 mx-2 self-baseline">:</span>
                        <div class="flex justify-center items-center flex-col w-[44px] h-[44px]">
                            <span class="promo__banner__second font-bold text-primary-500">${remainingTime['minutes']}</span>
                            <span class="text-sm font-light text-primary-400">Menit</span>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    `
}


export {promoSlide}
